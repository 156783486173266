import React, { useState } from "react";

export const LanguageContext = React.createContext();

export const LanguageProvider = (props) => {
  const [language, setLanguage] = useState("fr");

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {props.children}
    </LanguageContext.Provider>
  );
};
