exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-about-js": () => import("./../../../src/templates/about/About.js" /* webpackChunkName: "component---src-templates-about-about-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-communaute-partners-communaute-partners-js": () => import("./../../../src/templates/communautePartners/CommunautePartners.js" /* webpackChunkName: "component---src-templates-communaute-partners-communaute-partners-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/Default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-entreprise-entreprise-js": () => import("./../../../src/templates/entreprise/Entreprise.js" /* webpackChunkName: "component---src-templates-entreprise-entreprise-js" */),
  "component---src-templates-home-page-home-page-js": () => import("./../../../src/templates/homePage/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-home-page-js" */),
  "component---src-templates-newsroom-newsroom-js": () => import("./../../../src/templates/newsroom/Newsroom.js" /* webpackChunkName: "component---src-templates-newsroom-newsroom-js" */),
  "component---src-templates-partners-profile-partners-profile-js": () => import("./../../../src/templates/partnersProfile/PartnersProfile.js" /* webpackChunkName: "component---src-templates-partners-profile-partners-profile-js" */),
  "component---src-templates-single-post-single-post-js": () => import("./../../../src/templates/singlePost/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-single-post-js" */),
  "component---src-templates-unsubscribe-unsubscribe-js": () => import("./../../../src/templates/unsubscribe/Unsubscribe.js" /* webpackChunkName: "component---src-templates-unsubscribe-unsubscribe-js" */),
  "component---src-templates-why-interim-why-interim-js": () => import("./../../../src/templates/whyInterim/WhyInterim.js" /* webpackChunkName: "component---src-templates-why-interim-why-interim-js" */),
  "component---src-templates-why-iziwork-why-iziwork-js": () => import("./../../../src/templates/whyIziwork/WhyIziwork.js" /* webpackChunkName: "component---src-templates-why-iziwork-why-iziwork-js" */),
  "component---src-templates-why-partners-why-partners-js": () => import("./../../../src/templates/whyPartners/WhyPartners.js" /* webpackChunkName: "component---src-templates-why-partners-why-partners-js" */),
  "slice---src-components-header-main-js": () => import("./../../../src/components/headerMain.js" /* webpackChunkName: "slice---src-components-header-main-js" */),
  "slice---src-components-header-partners-js": () => import("./../../../src/components/headerPartners.js" /* webpackChunkName: "slice---src-components-header-partners-js" */),
  "slice---src-components-molecules-footer-main-js": () => import("./../../../src/components/molecules/FooterMain.js" /* webpackChunkName: "slice---src-components-molecules-footer-main-js" */),
  "slice---src-components-molecules-footer-secondary-js": () => import("./../../../src/components/molecules/FooterSecondary.js" /* webpackChunkName: "slice---src-components-molecules-footer-secondary-js" */)
}

